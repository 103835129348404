<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({ name: 'contracts' })"><icon-arrow-back /></div></div>
    <div class="content opacity-page">
        <div class="header-form">
            <h1 class="title">{{ $t('contract-co-last-years.title') }}</h1>
            <div class="actions">
                <button class="btn-tbf white only-icon" @click="$router.push({ name: 'contracts' })"><icon-close class="icon-close" /></button>
            </div>
		</div>
        <div class="content co_days_remains">

        <div class="form-group co_days_remains" :class="{empty: users.length == 0}">
            <div class="form">
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-25'">
                    <div class="label-header">
                        <label class="label">{{ $t('contract-co-last-years.choose_year') }}*</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-calendar /></div>
                        <multiselect 
                            v-model="selectedYear"
                            class="select-tags-tbf"
                            :options="years"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="date" 
                            label="year"
                            @input="getUsers()"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('tickets.ticket_type-ph') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div v-if="users.length > 0" class="form" v-for="(user, index) in users" :key="'user'+index">
                <div class="input-group w-20">
                    <div v-if="index == 0" class="label-header">
                        <label class="label">{{ $t('general.user-name') }}</label>
                    </div>
                    <div class="text-name">
                        {{user.first_name + ' ' + user.last_name}}
                    </div>
                </div>
                <div class="input-group" :class="[{'w-25':$resize && !$mq.above(600)},{'w-10':$resize && $mq.above(600)}]">
                    <div v-if="index == 0" class="label-header">
                        <label class="label">{{ $t('contract-co-last-years.remaining_days') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: user.number === ''}">
                        <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" class="input-text no-icon" v-model.number="user.remaining_days">
                    </div>
                </div>
                <div class="input-group" :class="[{'w-50':$resize && !$mq.above(600)},{'w-70':$resize && $mq.above(600)}]">
                    <div v-if="index == 0" class="label-header">
                        <label class="label">{{ $t('contract-co-last-years.observations') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: user.observations === ''}">
                        <input type="text" class="input-text no-icon" v-model="user.observations">
                    </div>
                </div>
            </div>
           <div v-else class="row-space-tbf row-list-item">
                <div class="content">
                    <div class="column-empty-list">
                        <div class="name">{{ $t('congratulations.empty-inline') }}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="form-submit crud">
        <!-- <div class="error-msg" v-show="error_message != ''">{{error_message}}</div> -->
        <button class="btn-tbf grey" @click="$router.push({ name: 'contracts' })"><span class="text">{{ $t('general.cancel') }}</span></button>
        <button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
            <div class="loader"></div>
            <span class="text">{{ $t('general.add') }}</span>
        </button>
        <button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
            <div class="loader"></div>
            <span class="text">{{ $t('general.update') }}</span>
        </button>
    </div>
</div>
</template>

<script>
    import IconCalendar from '../../../Icons/Calendar'
	import IconClose from '../../../Icons/Close'
    import IconArrowBack from '../../../Icons/ArrowLong'

    export default {
    	components: {
            IconArrowBack,
            IconCalendar,
			IconClose
        },
        data() {
            return {
            	loaded: false,
            	users: [],
                years: [],

                selectedYear: {}
            }
        },
        async mounted(){
            await this.getYears()
            await this.getUsers()
        },
		computed: {
        },
        
        methods: {
            async getYears() {
				await axios.get(`${this.$auth.user().instance.id}/months`)
				.then(({data}) => {
                    this.years = data.data.reduce((unique, o) => {
                        if(!unique.some(obj => obj.year === o.year)) {
                            unique.push(o);
                        }
                        return unique;
                    },[]);
                    this.selectedYear = this.years.find(el => el.year == new Date().getFullYear()-1)
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

            async getUsers() {
                await axios.get(`${this.$auth.user().instance.id}/annual-leave-remaining-days/create?year=${this.selectedYear.year}`).then(({data}) => {
                   this.users = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

           saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

                let objData = {}
                objData.remaining_days = this.users.map(el => {
                    return{
                        id: el.id,
                        contract_id: el.contract_id,
                        year: el.year,
                        ticket_type_id: el.ticket_type_id,
                        observations: el.observations,
                        remaining_days: el.remaining_days
                    }
                })
                this.createCoDaysRemains(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
			},
            createCoDaysRemains(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/annual-leave-remaining-days/store', objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'contracts' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
			},
			
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
        }
    }
</script>

<style lang="scss">
.text-name {
    font-size: 14px;
    height: 45px;
    align-items: center;
    display: flex;
    .circle-status{
        height: 10px;
        width: 10px;
        min-width: 10px;
        border-radius: 50%;
        background: #c5c5c5;
        border: 1px solid $secondary;
        margin-right: 7px;
        &.active{
            background: #6CE0A6;
            border: 1px solid #03AE58;
        }
    }
}
.multiselect{
    &.ticket {
        .multiselect__single {
            padding: 0 15px;
        }
        .multiselect__content-wrapper {
            width: 150px;
        }
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>